import React from "react"
import Vimeo from "@u-wave/react-vimeo"

const VideoRow = ({ data }) => {
  return (
    <div
      className={`pointer-events-auto relative ${
        !data.showControls ? "h-screen" : ""
      } w-full`}
    >
      {!data.showControls && (
        <div className="absolute top-0 left-0 h-screen w-full bg-transparent z-20" />
      )}
      <div
        className={`flex flex-col justify-center items-center overflow-hidden ${
          data.margins !== null
            ? ""
            : "supports-[height:100cqh]:h-[100cqh] supports-[height:100svh]:h-[100svh]"
        } w-full ${data.margins !== null ? "md:px-10" : "px-0"}`}
      >
        <div
          className={`embed-container w-full ${
            data.margins !== null ? "" : "h-full full-bleed"
          }`}
        >
          <Vimeo
            // className={`vimeo-player object-cover w-full h-full ${
            //   !!data.showControls ? "pointer-events-auto" : "pointer-events-none"
            // }`}
            className={`vimeo-player object-cover w-full ${
              !data.showControls ? "h-screen" : ""
            }`}
            autoplay={true}
            video={data.vimeoId}
            background={!!data.showControls ? false : true}
            responsive={false}
            loop={true}
            controls={true}
            dnt={true}
            volume={0}
          />
        </div>
      </div>
    </div>
  )
}

export default VideoRow
