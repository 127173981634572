import React, { useContext } from "react"
import { motion } from "framer-motion"
import { ThemeContext } from "./context/ThemeContext"
import { useSplashScreen } from "../hooks/useSplashScreen"
import VideoRow from "./layout/video"
import Logo from "../../assets/logo/a25-logo-w.svg"
import { Link } from "gatsby"
const SplashScreen = () => {
  const { wpPage } = useSplashScreen()
  const { setIsFirstTime } = useContext(ThemeContext)

  const randomVimeoId =
    wpPage.splashScreen.vimeoIds[
      Math.floor(Math.random() * wpPage.splashScreen.vimeoIds.length)
    ].id

  return (
    <motion.div
      className="fixed top-0 left-0 bg-black w-screen flex flex-col items-center justify-center relative z-50 supports-[height:100cqh]:h-[100cqh] supports-[height:100svh]:h-[100svh]"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={() => setIsFirstTime(false)}
    >
      <VideoRow
        data={{ vimeoId: randomVimeoId, margins: null, showControls: false }}
      />
      <div className="absolute w-screen h-full  top-0 left-0 z-50 flex flex-col items-center justify-center hover:cursor-pointer mix-blend-difference">
        <div className="w-[260px] md:w-[400px] h-[80px] md:h-[105px]">
          <Link
            to="/"
            className="w-full h-full  flex flex-col items-center justify-center"
          >
            <Logo />
          </Link>
        </div>
      </div>
    </motion.div>
  )
}

export default SplashScreen
